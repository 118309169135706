// src/components/MapTooltip.jsx
import React, { useEffect } from 'react';

const WorldMapToolTip = () => {
  useEffect(() => {
    const tooltip = document.getElementById("tooltip");
    const countries = document.querySelectorAll(".map-image-svg a");

    const handleMouseMove = (e) => {
      const title = e.currentTarget.getAttribute("title");
      tooltip.style.display = "block";
      tooltip.textContent = title;
      
      let left = e.clientX + 15;
      let top = e.clientY + 15;
      
      if (left + tooltip.offsetWidth > window.innerWidth) {
        left -= tooltip.offsetWidth + 30;
      }
      
      if (top + tooltip.offsetHeight > window.innerHeight) {
        top -= tooltip.offsetHeight + 30;
      }
      
      tooltip.style.left = `${left}px`;
      tooltip.style.top = `${top}px`;
    };

    const handleMouseLeave = () => {
      tooltip.style.display = "none";
    };

    countries.forEach(country => {
      country.addEventListener('mousemove', handleMouseMove);
      country.addEventListener('mouseleave', handleMouseLeave);
    });

    return () => {
      countries.forEach(country => {
        country.removeEventListener('mousemove', handleMouseMove);
        country.removeEventListener('mouseleave', handleMouseLeave);
      });
    };
  }, []);

  return null; // This component does not render anything itself
};

export default WorldMapToolTip;
